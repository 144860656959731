<template>
  <section
    id="services"
    class="overflow-hidden"
  >
    <v-row
      class=""
      no-gutters
    >
      <v-col
        class="hidden-sm-and-down"
        md="6"
      >
        <v-img
          :src="require('@/assets/brown-colors-960x1080.jpg')"
          height="100%"
        />
      </v-col>

      <v-col
        class="text-center pa-5"
        cols="12"
        md="6"
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <v-col cols="12">
            <base-bubble-2
              style="transform: translateX(55%)"
            />
            <base-heading class="">
              Services
            </base-heading>

            <base-text class="mb-5">
              Some of the services I focus on!
            </base-text>
          </v-col>

          <v-col
            v-for="(service, i) in services"
            :key="i"
            class="text-center mb-3"
            md="6"
          >
            <v-avatar
              class="elevation-6 mb-2"
              color="info"
              size="64"
              tile
            >
              <v-icon
                dark
                size="52"
                v-text="service.icon"
              />
            </v-avatar>

            <base-text>
              <div
                class="mb-2"
                v-text="service.name"
              />
              <div v-html="service.blurb" />
            </base-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'Services',

    data: () => ({
      services: [
        {
          name: 'Interior/Exterior Paint',
          icon: 'mdi-check-outline',
          blurb: '',
        },
        {
          name: 'Staining',
          icon: 'mdi-check-outline',
          blurb: '',
        },
        {
          name: 'Line Striping',
          icon: 'mdi-check-outline',
          blurb: '',
        },
        {
          name: 'Cabinetry',
          icon: 'mdi-check-outline',
          blurb: '',
        },
      ],
    }),
  }
</script>
